import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Symbol from "../assets/images/symbol.inline.svg";

type ThankYouPageProps = {
  contentfulLayoutThankYouPage: {
    id: string;
    heading: string;
    title: {
      title: string;
    };
    checkIcon: {
      gatsbyImageData: any;
    };
    subtitle: string;
    description: {
      description: string;
    };
    descriptionForMobile: {
      descriptionForMobile: string;
    };
  };
  contentfulLayout: {
    id: string;
    title: string;
    description: string;
    contentful_id: string;
    menu: {
      name: string;
      type: string;
      menuItems: {
        id: string;
        title: string;
        url: string;
      };
    };
    contentModule: {
      __typename: string;
      id: string;
    };
  };
};

const ThankYouPage: React.FC<ThankYouPageProps> = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      contentfulLayoutThankYouPage {
        id
        heading
        title {
          title
        }
        checkIcon {
          gatsbyImageData(placeholder: NONE)
        }
        subtitle
        description {
          description
        }
        descriptionForMobile {
          descriptionForMobile
        }
      }
      contentfulLayout(slug: { eq: "404" }) {
        id
        title
        description
        contentful_id
        menu {
          name
          type
          menuItems {
            id
            title
            url
          }
        }
        contentModule {
          __typename
          ... on Node {
            id
          }
        }
      }
    }
  `);

  const content = data.contentfulLayoutThankYouPage;
  const menus = data.contentfulLayout.menu;

  const image = getImage(content.checkIcon);

  return (
    <Layout menus={menus}>
      <section className="container section mx-auto text-center py-24 md:py-40 lg:py-24">
        <div className="flex flex-col justify-center mx-auto">
          <div className="section__title-container mb-4 md:mb-5">
            <h2 className="section__title text-xl md:text-2xl -mt-12 lg:text-3xl 2xl:text-4xl">
              {content.heading}
            </h2>
            <Symbol
              className="section__symbol"
              alt="医療機器管理システム HITOTSU ロゴ横棒"
              loading="eager"
            />
          </div>
          <h3 className="text-xl font-bold my-4 whitespace-pre-wrap md:whitespace-nowrap md:text-2xl lg:text-3xl lg:my-6 2xl:text-4xl 2xl:my-12">
            {content.title.title}
          </h3>

          <GatsbyImage
            image={image}
            alt={content.subtitle}
            className="object-scale-down h-24 xl:h-32 m-4"
            loading="eager"
            objectFit="contain"
          />
          <p
            className="text-sm xl:text-base 2xl:text-xl"
            style={{ color: "#44A3DA" }}
          >
            {content.subtitle}
          </p>
          {typeof window !== "undefined" && window.innerWidth < 768 ? (
            <p className="text-sm xl:text-base 2xl:text-xl my-8 xl:my-14">
              担当者よりご登録いただいた
              <br />
              メールアドレスにご連絡致します。
              <br />
              ご不明点などあれば、
              <br />
              <a className="text-primary" href="mailto: info@hitotsu.co.jp">
                info@hitotsu.co.jp
              </a>
              にお問い合わせください。
            </p>
          ) : (
            <p className="text-sm my-8 lg:mt-10 xl:text-base 2xl:text-xl 2xl:mb-12 2xl:mt-16">
              担当者よりご登録いただいたメールアドレスにご連絡致します。
              <br />
              ご不明点などあれば、
              <a className="text-primary" href="mailto: info@hitotsu.co.jp">
                info@hitotsu.co.jp
              </a>
              にお問い合わせください。
            </p>
          )}

          <div>
            <button className="btn btn--primary rounded-full text-center w-52 lg:w-60 xl:w-72 2xl:w-80">
              <Link to="/">トップページへ戻る</Link>
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYouPage;
